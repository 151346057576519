import { Box } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export const CenterPanel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div>
      {children}
      <Box height={80} />
    </div>
  );
};