import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { Maybe, DollarTag, Hashtag, Club } from "api";
import { User } from "oidc-client-ts";
import React, { FC } from "react";

const DisplayRestElementTypes: FC<{
  data: Array<
    Maybe<User> | Maybe<DollarTag> | Maybe<Hashtag> | Maybe<Club> | undefined
  >;
}> = ({ data }) => (
  <Stack direction="row" alignItems="center" flexWrap="wrap">
    {data
      .map((d: any) => {
        if (d?.__typename === "DollarTag") {
          return [
            <SmallText key="on" variant="caption">
              on
            </SmallText>,
            <SmallText
              key={d.dollarTagName}
              variant="caption"
              sx={{ color: "#1b6ac9", textWrap: "nowrap" }}
            >
              ${d.dollarTagName}
            </SmallText>,
          ];
        }
        if (d?.__typename === "Hashtag") {
          return [
            <SmallText key="with" variant="caption">
              with
            </SmallText>,
            <SmallText
              key={d.hashtagName}
              variant="caption"
              sx={{ color: "#1b6ac9" }}
            >
              #{d.hashtagName}
            </SmallText>,
          ];
        }
        if (d?.__typename === "Club") {
          return [
            <SmallText key="in" variant="caption">
              in
            </SmallText>,
            <SmallText
              key={d.clubName}
              variant="caption"
              sx={{ color: "#1b6ac9" }}
            >
              {d.clubName}
            </SmallText>,
          ];
        }
        return [];
      })
      .flatMap((x) => x)}
  </Stack>
);

export default DisplayRestElementTypes;
const SmallText = styled(Typography)({
  fontSize: "0.725rem",
  marginRight: 4,
});
