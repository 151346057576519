import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Maybe, DollarTag, Hashtag, Club } from "api";
import { User } from "oidc-client-ts";
import React,{ FC } from "react";

const DisplayFirstElementType: FC<{
  data:
    | Maybe<User>
    | Maybe<DollarTag>
    | Maybe<Hashtag>
    | Maybe<Club>
    | undefined
    | any;
}> = ({ data }) => {
  if (data?.__typename === "User") {
    return <SmallText variant="caption">in People</SmallText>;
  }
  if (data?.__typename === "Club") {
    return <SmallText variant="caption">in Clubs</SmallText>;
  }
  if (data?.__typename === "DollarTag") {
    return <SmallText variant="caption">in {data?.dollarTagType}</SmallText>;
  }
  if (data?.__typename === "Hashtag") {
    return <SmallText variant="caption">in Topics</SmallText>;
  }
  return null;
};

export default DisplayFirstElementType;

const SmallText = styled(Typography)({
  fontSize: "0.725rem",
  marginRight: 4,
});
