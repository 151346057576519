import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Button,
  List,
  Paper,
  Skeleton,
  styled,
  Typography,
  TypographyProps
} from "@mui/material";
import { useGetSavedSearchQuery } from "api";
import RecentSearchCard from "components/RecentSearches/RecentSearchCard";
import { Link } from "react-router-dom";
import { SeeAllStack } from "style";
const SavedSearches = () => {
  const { data, loading } = useGetSavedSearchQuery();
  if (!loading && !data?.getSavedSearch?.length) return null;
  return (
    <CustomPaper elevation={0} data-tut="reactour__treanding_stocks">
      <SeeAllStack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: 1 }}
      >
        <Title>
          {loading ? <Skeleton width={220} height={30} /> : "Saved Searches"}
        </Title>

        <Button
          size="small"
          component={Link}
          to="/saved-searches"
          endIcon={<ArrowRightIcon />}
        >
          See All
        </Button>
      </SeeAllStack>

      <List sx={{ padding: 1 }}>
        {(loading ? Array.from(new Array(4)) : data?.getSavedSearch)
          ?.slice(0, 3)
          .map((searches, i) => (
            <RecentSearchCard key={i} data={searches} isSaved/>
          ))}
      </List>
    </CustomPaper>
  );
};

export default SavedSearches;

const CustomPaper = styled(Paper)`
  && {
    background-color: ${({ theme }: any) => theme.background.transparent};
    margin-top: 16px;
    border: 0;
  }
`;

const Title = styled((props: TypographyProps) => (
  <Typography variant="h1" {...props} />
))({
  "&&": {
    fontWeight: "bold",
    fontSize: "1.1244rem",
  },
});
