import HighlightedAchievements from "components/HighlightedAchievements";
import { Fragment, Suspense, lazy } from "react";
import SavedSearches from "./components/SavedSearches";

const ProductTour = lazy(() => import("./components/ProductTour"));
const UpcomingEvent = lazy(() => import("./components/UpcomingEvent"));
const TopTrendingStocks = lazy(() => import("./components/TopTrendingStocks"));
const TopTrendingSectors = lazy(
  () => import("./components/TopTrendingSectors")
);
const TopTrendingTopics = lazy(() => import("./components/TopTrendingTopics"));
// const ReferEarn = lazy(() => import("./components/ReferEarn"));

export const DefaultRightPanel = () => {
  return (
    <Fragment>
      <Suspense fallback={<div />}>
        <ProductTour />
      </Suspense>
      {/* <Suspense fallback={<div />}>
        <ReferEarn />
      </Suspense> */}
      <Suspense>
        <SavedSearches/>
      </Suspense>
      <HighlightedAchievements ga={{ event: "web_achievement_promotion" }} />
      <Suspense fallback={<div />}>
        <TopTrendingStocks />
      </Suspense>
      <Suspense fallback={<div />}>
        <TopTrendingSectors />
      </Suspense>
      <Suspense fallback={<div />}>
        <UpcomingEvent />
      </Suspense>
      <Suspense fallback={<div />}>
        <TopTrendingTopics />
      </Suspense>
    </Fragment>
  );
};
