import { Avatar } from "@mui/material";
import { Maybe, DollarTag, Hashtag, Club } from "api";
import { User } from "oidc-client-ts";
import React,{ FC } from "react";
import { getIsRingVisible } from "utills";
import UserAvatar from "components/Avatar";

const DisplayIcon: FC<{
  data:
    | Maybe<User>
    | Maybe<DollarTag>
    | Maybe<Hashtag>
    | Maybe<Club>
    | undefined
    | any;
}> = ({ data }) => {
  console.log("DisplayIcon", data);
  if (data?.__typename === "User") {
    return (
      <UserAvatar
        src={data?.avatar?.LowResUri}
        alt="avatar"
        hasRing={getIsRingVisible(data?.membershipLevel)}
      />
    );
  }
  if (data?.__typename === "Club") {
    return <Avatar src={data?.avatar?.LowResUri} />;
  }
  if (data?.__typename === "DollarTag") {
    return <Avatar>$</Avatar>;
  }
  if (data?.__typename === "Hashtag") {
    return <Avatar>#</Avatar>;
  }
  return null;
};

export default DisplayIcon;

