import React, { useCallback, useMemo } from "react";
import { IconButton, styled } from "@mui/material";
import {
  Maybe,
  useAddToRecentSearchMutation,
  useRemoveFromRecentSearchMutation,
  UserRecentSearchElementType,
  UserRecentSearchInput,
  UserRecentSearchOutput,
  UserSavedSearchOutput,
} from "api";
import { Link } from "react-router-dom";
import { SearchTag } from "layouts/MainLayout/components/Search";
import CloseIcon from "@mui/icons-material/Close";
import DisplayIcon from "./DisplayIcon";
import DisplayName from "./DisplayName";
import DisplayFirstElementType from "./DisplayFirstElementType";
import DisplayRestElementTypes from "./DisplayRestElementTypes";
import { sendEvent } from "ga4";
import { AddRemoveSavedSearchButton } from "components/Buttons";

const getFilterPostLink = (
  data: Maybe<UserRecentSearchOutput> | undefined | Maybe<UserSavedSearchOutput>
) => {
  const obj: Record<string, string> = {};
  if (data?.Club) {
    obj["clubId"] = data?.Club?.clubId as string;
  }
  if (data?.Dollartag) {
    obj["dollarId"] = data?.Dollartag?.dollarTagId?.toString();
  }
  if (data?.Hashtag) {
    obj["hashTagId"] = data?.Hashtag?.TagId as string;
  }
  if (data?.User) {
    obj["userId"] = data?.User?.id as string;
  }
  obj["searchId"] = data?.searchId as string;
  return {
    pathname: "/filter-posts",
    search: new URLSearchParams(obj).toString(),
  };
};

const getEntityLink = (
  data: Maybe<UserRecentSearchOutput> | undefined | Maybe<UserSavedSearchOutput>
) => {
  if (data?.Club) {
    return {
      pathname: `/club/${data?.Club?.clubId as string}`,
    };
  }
  if (data?.Dollartag) {
    return {
      pathname: `/${data?.Dollartag?.dollarTagType?.toLowerCase()}/${
        data?.Dollartag?.slug
      }/${data?.Dollartag?.dollarTagId}`,
    };
  }
  if (data?.Hashtag) {
    return {
      pathname: `/hashtag/${encodeURIComponent(
        data?.Hashtag?.hashtagName as string
      )}`,
    };
  }
  if (data?.User) {
    return {
      pathname: `/profile/${encodeURIComponent(
        data?.User?.username as string
      )}`,
    };
  }
  return `/home`;
};

interface PeopleRecentSearchCardProps {
  data: Maybe<UserRecentSearchOutput>;
  handleGetFilterPosts?: (data: SearchTag | undefined) => void;
  handleRemoveRecentSearch?: () => void;
  close?: () => void | undefined;
  canBeSavedSearch?: boolean;
  isSaved?: boolean | undefined;
}

const RecentSearchCard: React.FC<PeopleRecentSearchCardProps> = ({
  canBeSavedSearch,
  data,
  close,
}) => {
  const [addToRecentSearch] = useAddToRecentSearchMutation();

  const handleAddToRecentSearch = useCallback(() => {
    if (!data) return null;
    const input: Maybe<
      Maybe<UserRecentSearchInput> | Maybe<UserRecentSearchInput>[]
    > = [];
    const { User, Club, Dollartag, Hashtag } = data;
    if (User) {
      input.push({
        searchElementId: User.id,
        searchElementType: UserRecentSearchElementType.User,
      });
    }
    if (Club) {
      input.push({
        searchElementId: Club.clubId,
        searchElementType: UserRecentSearchElementType.Club,
      });
    }
    if (Dollartag) {
      input.push({
        searchElementId: Dollartag.dollarTagId.toString(),
        searchElementType: UserRecentSearchElementType.DollarTag,
      });
    }
    if (Hashtag) {
      input.push({
        searchElementId: Hashtag.TagId,
        searchElementType: UserRecentSearchElementType.HashTag,
      });
    }
    addToRecentSearch({
      variables: {
        data: input,
      },
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            getRecentSearch: (previous) => [
              data?.addToRecentSearch,
              ...previous,
            ],
          },
        });
      },
    });
    sendEvent({
      event: "clicked_on_recent_search",
    });
    close?.();
  }, [addToRecentSearch, data, close]);

  const [removeRecentSearch] = useRemoveFromRecentSearchMutation();
  const handleRemoveRecentSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      const searchId = data?.searchId;
      removeRecentSearch({
        variables: {
          searchId,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              getRecentSearch: (previous, { readField }) =>
                previous?.filter((d) => readField("searchId", d) !== searchId),
            },
          });
        },
      });
      sendEvent({
        event: "clear_recent_search",
      });
    },
    [data, removeRecentSearch]
  );

  const inputForAdd = useMemo(() => {
    const input: Maybe<
      Maybe<UserRecentSearchInput> | Maybe<UserRecentSearchInput>[]
    > = [];
    if (data?.User?.id) {
      input.push({
        searchElementId: data?.User?.id,
        searchElementType: UserRecentSearchElementType.User,
      });
    }
    if (data?.Club?.clubId) {
      input.push({
        searchElementId: data?.Club?.clubId,
        searchElementType: UserRecentSearchElementType.Club,
      });
    }
    if (data?.Dollartag?.dollarTagId) {
      input.push({
        searchElementId: data?.Dollartag?.dollarTagId?.toString(),
        searchElementType: UserRecentSearchElementType.DollarTag,
      });
    }
    if (data?.Hashtag?.TagId) {
      input.push({
        searchElementId: data?.Hashtag?.TagId,
        searchElementType: UserRecentSearchElementType.HashTag,
      });
    }

    return input;
  }, [data]);

  const renderComponent = useMemo(() => {
    if (!data) return null;
    const { Club, Dollartag, Hashtag, User } = data;
    const result = [User, Dollartag, Hashtag, Club].filter(Boolean);
    if (result.length === 0) return null;
    const [firstElement, ...restElement] = result;
    const to =
      result.length > 1 ? getFilterPostLink(data) : getEntityLink(data);
    return (
      <CardLink
        to={to}
        onClick={handleAddToRecentSearch}
        sx={{
          borderBottom: !canBeSavedSearch
            ? "none"
            : "1px solid rgba(0, 0, 0, 0.1);",
        }}
      >
        <DisplayIcon data={firstElement as any} />
        <TextWrap>
          <DisplayName data={firstElement as any} />
          {restElement?.length > 0 ? (
            <DisplayRestElementTypes data={restElement as any} />
          ) : (
            <DisplayFirstElementType data={firstElement as any} />
          )}
        </TextWrap>

        <CTADiv className="ctadiv">
          <AddRemoveSavedSearchButton
            searchId={data?.searchId}
            isSaved={data?.isSaved}
            inputForAdd={inputForAdd}
          />
          {close && (
            <IconButton
              sx={{ width: "40px", height: "40px", marginLeft: "auto" }}
              onClick={handleRemoveRecentSearch}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </CTADiv>
      </CardLink>
    );
  }, [data, handleAddToRecentSearch, handleRemoveRecentSearch]);

  return <>{renderComponent}</>;
};

export default RecentSearchCard;

const CardLink = styled(Link)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 6px 4px 6px 6px;
  text-decoration: none;
  cursor: pointer;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.025);
  //   .ctadiv {
  //     display: block;
  //   }
  // }
`;

const TextWrap = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 8px;
  gap: 4px;
`;
const CTADiv = styled("div")({
  marginLeft: "auto",
  display: "block",

  ".MuiIconButton-root": {},
});
