import React, { FC } from "react";
import { useMeQuery, useTurnOffHighlightForAchievementMutation } from "api";
import { Swiper, SwiperSlide } from "swiper/react";
import { isEmpty } from "lodash-es";
import "swiper/css";
import { useBadgeContext } from "context/badge/BadgeContext";
import { Button, Paper, Typography, styled } from "@mui/material";
import { CloseIconButton } from "components/Buttons";
import { LazyLoadImage, LazyLoadImageProps } from "react-lazy-load-image-component";
import { sendEvent, GAEvent } from "ga4";

const RedirectLazyLoadImage =
  LazyLoadImage as unknown as React.ComponentClass<LazyLoadImageProps>;
  
  
const HighlightedAchievements: FC<{ ga: GAEvent }> = ({ga}) => {
  const { data } = useMeQuery();
  const { handleOpenModal } = useBadgeContext();
  const [turnOff] = useTurnOffHighlightForAchievementMutation();

  const handleClose =
    (achievementType: string) =>
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        turnOff({
          variables: { achievementType },
          update: (cache) => {
            cache.evict({
              id: cache.identify({
                achievementType,
                __typename: "HighlightedAchievements",
              }),
            });
          },
        });
      };
  if (isEmpty(data?.me?.highlightedAchievements)) return null;
  return (
    <SwiperContainer>
      <Swiper spaceBetween={10} slidesPerView={1}>
        {data?.me?.highlightedAchievements?.map((achivement, key) => (
          <SwiperSlide key={key}>
            <AchievementPaper>
              <Icon src={achivement?.badges?.badgeIcon as string} alt="" />
              <ShareButtonContainer>
                <Text>You are now a {achivement?.badges?.badgeName}!</Text>
                <Button
                  variant="contained"
                  disableElevation
                  size="small"
                  onClick={() => {
                    sendEvent(ga)
                    handleOpenModal(
                      achivement?.achievementType as string,
                      achivement?.sourceOfClick as string
                    )
                  }
                  }
                >
                  View!
                </Button>
              </ShareButtonContainer>
              <CloseButton
                size="small"
                onClick={handleClose(achivement?.achievementType as string)}
              />
            </AchievementPaper>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperContainer>
  );
};

export default HighlightedAchievements;

const SwiperContainer = styled("div")({
  maxWidth: "min(100vw, var(--center-view-maxwidth))",
  "& .swiper-wrapper": {
    minWidth: 0,
  },
});

const ShareButtonContainer = styled("div")({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media (min-width: 960px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 10,
  },
});

const AchievementPaper = styled(Paper)({
  backgroundColor: "rgba(255, 255, 255, 0.024)",
  color: "rgb(27, 106, 201)",
  // borderColor: "#10336F",
  borderWidth: 1,
  borderStyle: "solid",
  display: "flex",
  flexDirection: "row",
  gap: 8,
  padding: "12px 8px",
  marginTop: 15,
  position: "relative",
  "@media (min-width: 540px)": {
    gap: 15,
    padding: "20px 15px",
  },
});

const Icon = styled(RedirectLazyLoadImage)({
  height: 60,
  width: "auto",
  "@media (min-width: 960px)": {
    height: 80,
  },
});

// 9831678020

const Text = styled(Typography)({
  fontSize: "1rem",
  lineHeight: "1.43rem"
});

const ShareButton = styled(Button)({
  backgroundImage: "linear-gradient(#FFD744 100%, #FF7B3D 100%)",
  borderColor: "#FFF3C8",
  borderWidth: 1,
  borderStyle: "solid",
  fontSize: "1rem",
  lineHeight: "1.43rem",
  color: "#000",
  width: 85,
  height: 34,
});

const CloseButton = styled(CloseIconButton)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  svg: {
    height: 16,
    width: 16,
  },
}));
