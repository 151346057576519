import { FC, Fragment, Suspense, lazy, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import HelmetWithNotificationCount from "components/HelmetWithNotificationCount";
import { PageLoading } from "components/PageLoading";
import FilterContainer from "./FilterContainer";
import { InputMaybe, PostCategoryForSearch } from "api";
import { SelectChangeEvent } from "@mui/material";
import { CenterPanel, RightPanel } from "layouts/MainLayout";

const Posts = lazy(() => import("./Posts"));

const FilterPosts: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    postCategory: PostCategoryForSearch.All,
  });

  // const { search } = useLocation();

  // const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const hashTagId = searchParams.get("hashTagId");
  const userId = searchParams.get("userId");
  const dollarId = searchParams.get("dollarId");
  const clubId = searchParams.get("clubId");
  const postCategory = searchParams.get("postCategory");

  console.log("query", hashTagId, userId, dollarId, clubId);

  console.log("searchParams", searchParams);
  const handleChange = (event: SelectChangeEvent<string | null>) => {
    searchParams.set("postCategory", event.target.value as string);
    setSearchParams(searchParams);
  };
  return (
    <Fragment>
      <CenterPanel>
        <Container>
          <HelmetWithNotificationCount
            title={`FilterPosts | StockEdge Social`}
          />
          <FilterContainer
            hashTagId={hashTagId ? hashTagId : ""}
            userId={userId ? userId : ""}
            dollarId={dollarId ? parseInt(dollarId) : undefined}
            clubId={clubId ? clubId : ""}
            postCategory={postCategory as string}
            handleChange={handleChange}
          />
          <Posts
            hashTagId={hashTagId ? hashTagId : ""}
            userId={userId ? userId : ""}
            dollarId={dollarId ? parseInt(dollarId) : undefined}
            clubId={clubId ? clubId : ""}
            postCategory={postCategory as InputMaybe<PostCategoryForSearch>}
          />
          <Suspense fallback={<PageLoading />}></Suspense>
        </Container>
      </CenterPanel>
      <RightPanel />
    </Fragment>
  );
};

export default FilterPosts;

const Container = styled.div`
  max-width: 540px;
  margin: auto;
`;
