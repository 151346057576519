import { Box, useMediaQuery, styled } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import SimpleBarReact from "simplebar-react";
import { DefaultRightPanel } from "./DefaultRightPanel";

export const RightPanel: FC<PropsWithChildren> = ({ children }) => {
  const isVisible = useMediaQuery("(min-width:960px)");
  if (isVisible)
    return (
      <StickyBox>
        <OnHoverScroll>
          {children ? children : <DefaultRightPanel />}
          <Box height={80} />
        </OnHoverScroll>
      </StickyBox>
    );
  return null;
};

const StickyBox = styled(Box)`
  flex: 1;
  height: calc(100vh - 64px);
  position: sticky;
  top: 64px;
`;

const OnHoverScroll = styled(SimpleBarReact)`
  max-height: calc(100vh - 64px);
  padding: 0 12px 8px 8px;
`;
