import React, { FC, Fragment } from "react";
import styled from "@emotion/styled";
import { MaxContainer } from "../../../style";
import { useGetBlockedListQuery } from "../../../api";
import { Paper, Typography } from "@mui/material";
import { LoadMoreLoader } from "components/LoadMoreLoader";
import { isEmpty } from "lodash-es";
import { EmptyBlockedList } from "./EmptyBlockedList";
import HelmetWithNotificationCount from "components/HelmetWithNotificationCount";
import { BlockedUserCard, UserCardGrid } from "components/UserCard";
import { CenterPanel, RightPanel } from "layouts/MainLayout";

const BlockedUsers: FC = () => {
  const { data, loading, error } = useGetBlockedListQuery({
    fetchPolicy: "network-only",
  });
  return (
    <Fragment>
      <CenterPanel>
        <MaxContainer>
          <HelmetWithNotificationCount
            title={`Blocked Users | StockEdge Social`}
          />
          <BlockedUsersHeader />
          <UserCardGrid>
            {data?.getBlockedList?.map((user, key) => {
              return <BlockedUserCard user={user} key={key} />;
            })}
          </UserCardGrid>
          {loading ? (
            <LoadMoreLoader />
          ) : (
            !!error || (isEmpty(data?.getBlockedList) && <EmptyBlockedList />)
          )}
        </MaxContainer>
      </CenterPanel>
      <RightPanel />
    </Fragment>
  );
};

export default BlockedUsers;

const BlockedUsersHeader = () => (
  <Header elevation={0}>
    <TitleContainer>
      <Typography variant="body1">Blocked Profiles</Typography>
      <Typography variant="caption">
        List of the profiles blocked by you.
      </Typography>
    </TitleContainer>
  </Header>
);

const Header = styled(Paper)`
  padding: 10px;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
