import { Typography } from "@mui/material";
import { Maybe, DollarTag, Hashtag, Club } from "api";
import BlueTick from "components/BlueTick";
import GreenTick from "components/GreenTick";
import YellowTick from "components/YellowTick";
import { User } from "oidc-client-ts";
import React,{ FC } from "react";

const DisplayName: FC<{
    data:
      | Maybe<User>
      | Maybe<DollarTag>
      | Maybe<Hashtag>
      | Maybe<Club>
      | undefined|any;
  }> = ({ data }) => {
    if (data?.__typename === "User") {
      return (
        <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
          {`${data?.firstName} ${data?.lastName}`}
          {data?.isVerified && <BlueTick />}
          {Boolean(data?.greenTick) && <GreenTick />}
          {Boolean(data?.tick3) && <YellowTick />}
        </Typography>
      );
    }
    if (data?.__typename === "Club") {
      return (
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "left" }}
        >{`${data.clubName}`}</Typography>
      );
    }
    if (data?.__typename === "DollarTag") {
      return (
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "left" }}
        >{`${data?.dollarTagName}`}</Typography>
      );
    }
    if (data?.__typename === "Hashtag") {
      return (
        <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
          {`${data?.hashtagName}`}
        </Typography>
      );
    }
    return null;
  };



  export default DisplayName